<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="mstTable"
          title="다국어 목록"
          tableId="mstTable"
          :columnSetting="false"
          :usePaging="true"
          :hideBottom="false"
          :columns="mstGrid.columns"
          :data="mstGrid.data"
          selection="multiple"
          rowKey="languageKr"
          :isExcelDown="true"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRowMst" v-if="editable && isManager" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeMst" v-if="editable && isManager" />
            <c-btn label="저장" icon="save" @btnClicked="saveMst" v-if="editable && isManager" />
            </q-btn-group>
          </template>
        </c-table>
        <q-dialog v-model="prompt" persistent>
          <q-card style="min-width: 350px">
            <q-card-section>
              <div class="text-h6">한국어 키</div>
            </q-card-section>
            <q-card-section class="q-pt-none">
              <q-input dense v-model="hiddenlanguageKey" autofocus @keyup.enter="setMst" />
            </q-card-section>
            <q-card-actions align="right" class="text-primary">
              <q-btn flat label="취소" @click="cancelMst" />
              <q-btn flat label="추가" @click="setMst" />
            </q-card-actions>
          </q-card>
        </q-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'lang-message',
  data() {
    return {
      prompt: false,
      hiddenlanguageKey: '',
      mstGrid: {
        columns: [
          {
            required: true,
            name: 'languageKey',
            field: 'languageKey',
            label: '한국어 키',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'languageKr',
            field: 'languageKr',
            label: '한국어',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'languageEn',
            field: 'languageEn',
            label: '영문',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'languageJa',
            field: 'languageJa',
            label: '일본어',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'languageZhCn',
            field: 'languageZhCn',
            label: '중국어간체',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'languageZhTw',
            field: 'languageZhTw',
            label: '중국어번체',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      mstlistUrl: '',
      checkUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      isManager: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.isManager = (this.$store.getters.user.userId === 'U000000001');
      this.dataeditable = false;
      this.mstlistUrl = selectConfig.sys.language.list.url;
      this.mstSaveUrl = transactionConfig.sys.language.save.url;
      this.mstDeleteUrl = transactionConfig.sys.language.delete.url;
      this.checkUrl = selectConfig.sys.language.check.url;
      this.getMsts();
    },
    getMsts() {
      this.$http.url = this.mstlistUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
        this.selectedMstCd = null;
      },);
    },
    addRowMst() {
      this.prompt = true;
    },
    cancelMst() {
      this.hiddenlanguageKey = '';
      this.prompt = false;
    },
    setMst() {
      
      this.$http.url = this.checkUrl;
      this.$http.type = 'GET';
      this.$http.param = {languageKey: this.hiddenlanguageKey}
      this.$http.request((_result) => {
        if (_result.data === 0) {
          this.prompt = false;
          this.mstGrid.data.splice(0, 0, {
            editFlag: 'C',
            languageKey: this.hiddenlanguageKey,
            languageKr: '',
            languageEn: '',
            languageJa: '',
            languageZhCn: '',
            languageZhTw: '',
            useFlag: 'Y',
          })
          this.hiddenlanguageKey = '';
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '동일한 한국어 키가 존재합니다.', // 취소사유를 입력하시기 바랍니다.
            type: 'warning', // success / info / warning / error
          });
          this.hiddenlanguageKey = '';
          return;
        }
      },);
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });

        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId
          item.chgUserId = this.$store.getters.user.userId
        })

        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getMsts();
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeMst() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {

        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.mstDeleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
              })
              this.$refs['mstTable'].$refs['compo-table'].clearSelection();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
